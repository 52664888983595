import { FC, useMemo } from 'react'
import { useStyles } from './styles'

type Props = Partial<{
  onChange: (value: string) => void
  textarea: boolean
  label: string
  name: string
  type: string
  defaultValue: string
}>

const InputField: FC<Props> = props => {
  const name = useMemo(() => `biceps-form__${props.name}`, [props.name])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={name}>
        {props.label}
      </label>
      {props.textarea ? (
        <textarea
          className={classes.input}
          id={name}
          name={name}
          required
          style={{ height: 140 }}
          defaultValue={props.defaultValue}
          onChange={event => {
            props.onChange && props.onChange(event.currentTarget.value)
          }}
        />
      ) : (
        <input
          className={classes.input}
          id={name}
          name={name}
          required
          type={props.type || 'text'}
          defaultValue={props.defaultValue}
          onChange={event => {
            props.onChange && props.onChange(event.currentTarget.value)
          }}
        />
      )}
    </div>
  )
}

export default InputField
