import { FC } from 'react'
import { SpacerType } from '../../core/hooks/useComposition'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

type Props = {
  component: SpacerType
}

const Spacer: FC<Props> = ({ component }) => {
  const { mq } = useMediaQuery()

  const classes = useStyles({ ...component.options, mq })

  return <div className={classes.root}></div>
}

export default Spacer
