export const jssTheme = {
  palette: {
    background: {
      grey: '#f9f9f9',
    },
    text: {
      grey: '#272727',
    },
  },
  typography: {
    size: {
      small: '12px',
    },
  },
}
