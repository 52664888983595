import { createUseStyles } from 'react-jss'
import { GlobalOptions } from '../../core/hooks/useGlobalOptions'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = {
  mq: MQ
  options: GlobalOptions
}

export const useStyles = createUseStyles({
  root: ({ options }: Props) => ({
    fontFamily: `"${options?.titleFont}"`,
    fontWeight: 600,
    lineHeight: '32px',
    textTransform: 'uppercase',
  }),
  h1: ({ mq }: Props) => ({
    fontSize: mq.down.lg ? (mq.down.md ? '24px' : '32px') : '40px',
  }),
  h2: {
    fontSize: '24px',
  },
  h3: {
    fontSize: '20px',
    fontWeight: 500,
  },
})
