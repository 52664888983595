import { FC, useContext } from 'react'
import Image from '../../components/Image'
import NativeButton from '../../components/NativeButton'
import Title from '../../components/Title'
import { MediaAndTextType } from '../../core/hooks/useComposition'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'
import { BrandContext } from '../../core/hooks/useBrand'

type Props = {
  component: MediaAndTextType
}

const MediaAndText: FC<Props> = ({ component }) => {
  const { mq } = useMediaQuery()
  const brand = useContext(BrandContext)

  const classes = useStyles({ mq })

  const mediaElement = (
    <div className={classes.mediaBox}>
      {component.content.image ? (
        <div
          aria-label={component.content.title}
          className={classes.backgroundCover}
          style={{
            paddingTop: `${
              (component.options.ratio.height / component.options.ratio.width) *
              100
            }%`,
          }}
        >
          <div className={classes.pictureContainer}>
            <Image
              src1x={component.content.image.src1x}
              alt={component.content.title}
              fit="cover"
            />
          </div>
        </div>
      ) : component.content.video ? (
        <div className={classes.video}>
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={classes.iframe}
            frameBorder={0}
            height="100%"
            title="YouTube video player"
            width="100%"
            src={`https://www.youtube-nocookie.com/embed/${
              component.content.video.url.split('/')[3]
            }?modestbranding=1&rel=0&color=white`}
          />
        </div>
      ) : null}
    </div>
  )

  return (
    <div className={classes.root}>
      {(component.options.mediaPosition === 1 || mq.down.lg) && mediaElement}
      <div className={classes.textBox}>
        <div>
          {component.content.title && (
            <Title className={classes.title}>{component.content.title}</Title>
          )}
          {component.content.paragraph && (
            <div className={classes.paragraph}>
              {component.content.paragraph.split('\n').map((line, index) => (
                <p
                  key={index}
                  style={{ fontSize: brand === 'bem' ? 16 : undefined }}
                >
                  {line}
                </p>
              ))}
            </div>
          )}
        </div>
        {component.content.ctaLink &&
          (component.content.ctaLink.isInternal ? null : (
            <NativeButton
              className={classes.cta}
              href={component.content.ctaLink.url}
            >
              {component.content.ctaLabel}
            </NativeButton>
          ))}
      </div>
      {component.options.mediaPosition === 2 && mq.up.md && mediaElement}
    </div>
  )
}

export default MediaAndText
