import { createUseStyles } from 'react-jss'
import { BannerType } from '../../core/hooks/useComposition'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = BannerType['options'] & { mq: MQ }

export const useStyles = createUseStyles({
  root: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  image: ({ imageSize }: Props) => ({
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom:
      imageSize === 1
        ? '25%'
        : imageSize === 2
        ? '33.33%'
        : imageSize === 3
        ? '56.25%'
        : '10%',
    position: 'relative',
  }),
  title: ({ titleHue, titleAlignment }: Props) => ({
    color: titleHue === 1 && 'white',
    width: 'calc(100% - 64px)',
    textAlign:
      titleAlignment[0] === 1
        ? 'left'
        : titleAlignment[0] === 2
        ? 'center'
        : 'right',
    margin: 0,
    position: 'absolute',
    top:
      titleAlignment[1] === 1 ? '0' : titleAlignment[1] === 2 ? '50%' : '100%',
    left: '50%',
    transform: `translate(-50%, ${
      titleAlignment[1] === 1
        ? '32px'
        : titleAlignment[1] === 2
        ? '-50%'
        : 'calc(-100% - 32px)'
    })`,
  }),
})
