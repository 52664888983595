import { createUseStyles } from 'react-jss'
import { SpacerType } from '../../core/hooks/useComposition'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = SpacerType['options'] & { mq: MQ }

export const useStyles = createUseStyles({
  root: ({ mq, desktopHeight, tabletHeight, mobileHeight }: Props) => {
    let height = desktopHeight

    if (mq.down.md) {
      height = mobileHeight
    } else if (mq.down.lg) {
      height = tabletHeight
    }

    return {
      height,
    }
  },
})
