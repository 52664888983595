import clsx from 'clsx'
import { FC, useContext } from 'react'
import { GlobalOptionsContext } from '../../core/hooks/useGlobalOptions'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

type Props = Partial<{
  className: string
  variant: 'h1' | 'h2' | 'h3' | 'h4'
}>

const Title: FC<Props> = ({ children, className, ...props }) => {
  const { mq } = useMediaQuery()
  const options = useContext(GlobalOptionsContext)

  const classes = useStyles({ mq, options })

  switch (props.variant) {
    case 'h1':
      return (
        <h1 className={clsx(classes.root, classes.h1, className)} {...props}>
          {children}
        </h1>
      )
    case 'h2':
    default:
      return (
        <h2 className={clsx(classes.root, classes.h2, className)} {...props}>
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h3 className={clsx(classes.root, classes.h3, className)} {...props}>
          {children}
        </h3>
      )
    case 'h4':
      return (
        <h4 className={clsx(classes.root, className)} {...props}>
          {children}
        </h4>
      )
  }
}

export default Title
