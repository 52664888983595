import clsx from 'clsx'
import { FC, useContext } from 'react'
import { GlobalOptionsContext } from '../../core/hooks/useGlobalOptions'
import { useStyles } from './styles'

type Props = Partial<{
  className: string
  href: string
  onClick: () => void
  classes: {
    button: string
  }
}>

const Button: FC<Props> = ({ children, ...props }) => {
  const options = useContext(GlobalOptionsContext)

  const classes = useStyles({ options })

  return (
    <div className={clsx(classes.root, props.className)}>
      {props.href ? (
        <a
          className={classes.link}
          href={props.href}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      ) : (
        <button
          className={clsx(props.classes?.button, classes.link)}
          onClick={props.onClick}
        >
          {children}
        </button>
      )}
    </div>
  )
}

export default Button
