import { createUseStyles } from 'react-jss'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = {
  styles: {
    textAlign: number
  }
  mq: MQ
}

export const useStyles = createUseStyles({
  root: ({ mq, styles }: Props) => {
    let textAlign = ''

    switch (styles.textAlign) {
      case 1:
        textAlign = 'left'
        break
      case 2:
      default:
        textAlign = 'center'
        break
      case 3:
        textAlign = 'right'
        break
    }

    return {
      margin: mq.up.sm
        ? textAlign === 'center'
          ? 64
          : '64px 0 64px 0'
        : textAlign === 'center'
        ? 32
        : '32px 0 32px 0',
      textAlign,
    }
  },
})
