import { FC } from 'react'
import NativeButton from '../../../components/NativeButton'
import Title from '../../../components/Title'
import { CatalogueType } from '../../../core/hooks/useComposition'
import { useMediaQuery } from '../../../core/hooks/useMediaQuery'
import FilterCategoryView from '../FilterCategoryView'
import { FilterGroupProps } from '../FilterCategoryView/FilterCategoryView'
import { useStyles } from './styles'

type FiltersViewProps = {
  filterCategories?: CatalogueType['options']['filters']
  filterCategoryPool: FilterGroupProps[]
  keywords?: CatalogueType['keywords']
  onFilter: (arg: { key: string; id: string }) => void
  onReset: () => void
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchTerm: string
}

const FiltersView: FC<FiltersViewProps> = props => {
  const { mq } = useMediaQuery()

  const classes = useStyles({ mq })

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Title className={classes.label}>{props.keywords?.filters}</Title>
          <NativeButton onClick={props.onReset}>
            {props.keywords?.clear}
          </NativeButton>
        </div>
        <div className={classes.searchInputWrapper}>
          <label htmlFor={classes.searchInput} style={{ display: 'none' }}>
            {props.keywords?.search}
          </label>
          <input
            id={classes.searchInput}
            className={classes.searchInput}
            placeholder={props.keywords?.search}
            onChange={props.onSearch}
            value={props.searchTerm}
          />
        </div>
        {props.filterCategories?.map(filterCategory => {
          const currentFilterGroup = props.filterCategoryPool.find(
            fc => fc.key === filterCategory
          )

          return (
            currentFilterGroup && (
              <FilterCategoryView
                {...currentFilterGroup}
                keyCode={currentFilterGroup.key}
                onToggleFilter={props.onFilter}
              />
            )
          )
        })}
      </div>
    </div>
  )
}

export default FiltersView
