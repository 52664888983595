import { createUseStyles } from 'react-jss'
import { GlobalOptions } from '../../../core/hooks/useGlobalOptions'
import { MQ } from '../../../core/hooks/useMediaQuery'

type Props = {
  isExtended: boolean
  isEmpty: boolean
  mq: MQ
  options: GlobalOptions
}

export const useStyles = createUseStyles<string, Props>({
  root: {},
  button: ({ isExtended, isEmpty, mq }: Props) => ({
    cursor: 'pointer',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '14px',
    fontWeight: isExtended ? 600 : 300,
    letterSpacing: '1px',
    margin: mq.up.md ? '8px' : '8px auto',
    opacity: isEmpty ? 0.5 : 1,
    padding: '8px 16px',
    textAlign: 'left',
  }),
  filters: {
    overflow: 'hidden',
  },
  bemCategoryTitle: ({ options }) => ({
    fontSize: 14,
    fontFamily: `"${options?.titleFont}"`,
  }),
})
