import { createUseStyles } from 'react-jss'
import { GlobalOptions } from '../../core/hooks/useGlobalOptions'

type Props = { options: GlobalOptions }

export const useStyles = createUseStyles({
  root: ({ options }: Props) => ({
    background: 'white',
    color: '#424242',
    fontFamily: options?.paragraphFont,
    fontSize: '14px',
    letterSpacing: '1px',
    lineHeight: '28px',
    margin: 'auto',
    maxWidth: 1440,
    padding: '16px',
  }),
})
