import { createContext, useCallback, useState } from 'react'

type Param = {
  key: string
  id: string
}

export const useFilters = () => {
  const [params, setParams] = useState<Param[]>([])

  const reset = useCallback(() => {
    setParams([])
  }, [])

  const toggle = useCallback(
    (param: Param, options?: { withReset?: boolean }) => {
      const filterIndex = params.findIndex(
        p => p.key === param.key && p.id === param.id
      )
      const newParams = [...params]

      filterIndex > -1
        ? newParams.splice(filterIndex, 1)
        : newParams.push(param)

      setParams(options?.withReset ? [param] : newParams)
    },
    [params, setParams]
  )

  return { params, toggle, reset }
}

export const FiltersContext = createContext<ReturnType<typeof useFilters>>({
  params: [],
  toggle: (param: Param) => {},
  reset: () => {},
})
