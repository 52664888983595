import { createUseStyles } from 'react-jss'
import { Brand } from '../../../core/hooks/useComposition'
import { GlobalOptions } from '../../../core/hooks/useGlobalOptions'
import { jssTheme } from '../../../core/theme'
import { Nullable } from '../../../core/tools/utilityTypes'

type Props = {
  brand: Nullable<Brand>
  options: GlobalOptions
}

export const useStyles = createUseStyles<string, Props, typeof jssTheme>({
  root: {
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'background 0.2s',
    position: 'relative',
    '&:hover': {
      '& img': {
        width: '105%',
        height: '105%',
      },
      '& button': {
        opacity: 1,
      },
    },
  },
  novelty: ({ brand, options }) => ({
    background: '#ffffff',
    boxSizing: 'border-box',
    fontSize: 12,
    padding: '0 24px',
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    zIndex: 1,
    top: '8px',
    right: '8px',
    fontFamily: brand === 'bem' && `"${options?.titleFont}"`,
  }),
  imageWrapper: {
    background: '#f9f9f9',
    padding: '16px',
    position: 'relative',
    margin: 1,
  },
  image: {
    paddingTop: '100%',
    position: 'relative',
    '& img': {
      height: '100%',
      left: '50%',
      objectFit: 'contain',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      transition: 'width 0.2s, height 0.2s',
      width: '100%',
    },
  },
  info: {
    padding: '16px',
    textAlign: 'center',
  },
  title: ({ brand }) => ({
    display: 'block',
    lineHeight: '20px',
    marginBottom: '16px',
    fontWeight: 600,
    fontSize: brand === 'bem' && 24,
  }),
  bemTitleCollection: {},
  bemTitleReference: ({ options }) => ({
    fontSize: 16,
    fontFamily: `"${options?.titleFont}"`,
  }),
  reference: ({ brand, options }) => ({
    display: 'block',
    fontWeight: 300,
    fontSize: brand === 'bem' && 12,
    fontFamily: `"${options?.titleFont}"`,
  }),
  price: {
    display: 'block',
    fontWeight: 600,
  },
  findOutMoreButton: {
    transition: 'opacity 0.2s',
    opacity: 0,
    margin: '16px auto 0 auto',
  },
})
