import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {
    margin: '16px',
  },
  title: {
    textAlign: 'center',
    margin: 0,
  },
  socialNetworks: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialNetwork: {
    margin: '16px',
    display: 'inline-block',
    lineHeight: 0,
  },
})
