import { createUseStyles } from 'react-jss'
import { GlobalOptions } from '../../core/hooks/useGlobalOptions'
import { jssTheme } from '../../core/theme'

type Keys = 'root'

type Props = {
  disabled?: boolean
  noBorder?: boolean
  noUppercase?: boolean
  options: GlobalOptions
}

export const useStyles = createUseStyles<Keys, Props, typeof jssTheme>(
  theme => ({
    root: ({ noBorder, noUppercase, disabled, options }) => ({
      background: 'none',
      border: noBorder ? 'none' : 'solid 1px #dbdbdb',
      color: !disabled && 'inherit',
      cursor: 'pointer',
      display: 'block',
      fontFamily: `"${options?.titleFont}"`,
      fontSize: '14pxem',
      letterSpacing: 'inherit',
      lineHeight: '1.25em',
      padding: '8px 32px',
      textDecoration: 'none',
      textTransform: noUppercase ? 'none' : 'uppercase',
      transition: 'background 0.2s',
      '&:hover': {
        background: theme.palette.background.grey,
      },
    }),
  })
)
