import { createUseStyles } from 'react-jss'
import { MQ } from '../../core/hooks/useMediaQuery'
import { jssTheme } from '../../core/theme'
import { GlobalOptions } from '../../core/hooks/useGlobalOptions'
import { Nullable } from '../../core/tools/utilityTypes'
import { Brand } from '../../core/hooks/useComposition'

type Props = {
  catalogueHeight: number
  mq: MQ
  productHeight: number
  reference?: string
  showTechnicalDetails: boolean
  globalOptions: GlobalOptions
  brand: Nullable<Brand>
}

export const useStyles = createUseStyles((theme: typeof jssTheme) => ({
  root: (props: Props) => ({
    marginTop: '64px',
    marginBottom: '32px',
    overflow: 'hidden',
  }),
  wrapper: ({ reference, catalogueHeight, productHeight }) => ({
    display: 'flex',
    left: reference ? '-100%' : 0,
    height: reference ? productHeight : catalogueHeight,
    overflow: 'hidden',
    position: 'relative',
    transition: 'left 0.4s, height 0.4s',
    width: '200%',
  }),
  catalogue: ({ reference }) => ({
    opacity: reference ? 0 : 1,
    transition: 'opacity 0.4s',
    position: 'relative',
    width: '100%',
  }),
  filtersAndProducts: ({ mq }) => ({
    alignItems: 'flex-start',
    display: mq.up.md && 'flex',
    marginBottom: '16px',
  }),
  paging: {
    display: 'flex',
    justifyContent: 'center',
  },
  previousButton: {
    marginRight: '8px',
  },
  productDetail: ({ reference }) => ({
    opacity: reference ? 1 : 0,
    transition: 'opacity 0.4s',
    width: '100%',
  }),
  backButton: {
    marginBottom: '16px',
  },
  productThumbnails: ({ mq }) => ({
    position: !mq.up.md && 'absolute',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '16px',
  }),
  productThumbnail: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    border: 'none',
    cursor: 'pointer',
    height: 100,
    marginBottom: '16px',
    padding: 0,
    width: 100,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  productTitle: {
    marginTop: 0,
  },
  productCollection: {
    fontWeight: 600,
  },
  productReference: {
    fontWeight: 300,
  },
  productNovelty: {
    background: '#dbdbdb',
    display: 'inline-block',
    fontSize: 12,
    padding: '0 12px',
    textTransform: 'uppercase',
  },
  technicalDetails: ({ showTechnicalDetails }) => ({
    boxSizing: 'border-box',
    marginBottom: showTechnicalDetails ? '16px' : 0,
    maxHeight: showTechnicalDetails ? 580 : 0,
    opacity: showTechnicalDetails ? 1 : 0,
    transition: 'margin-bottom 0.4s, max-height 0.4s, opacity 0.4s',
    overflow: 'auto',
  }),
  technicalDetailsWrapper: ({ mq }) => ({
    padding: mq.up.sm && '48px',
    boxSizing: 'border-box',
  }),
  productEditorial: ({ mq }) => ({
    padding: `48px ${mq.up.sm ? '48px' : 0}`,
  }),
  collectionDescription: {
    color: theme.palette.text.grey,
    fontSize: theme.typography.size.small,
    textAlign: 'justify',
  },
  collectionTitle: {
    textAlign: 'center',
  },
  detailWrapper: {
    marginBottom: '48px',
  },
  detailLabel: ({ mq }) => ({
    fontWeight: 600,
    margin: 0,
    marginBottom: '16px',
    textAlign: mq.down.md && 'center',
  }),
  bemDetailLabel: ({ globalOptions }) => ({
    fontSize: 16,
  }),
  detailRowWrapper: ({ brand }) => ({
    boxSizing: 'border-box',
    display: 'inline-block',
    width: '100%',
    paddingRight: '16px',
    fontSize: brand === 'bem' && 16,
  }),
  detailRow: {
    borderBottom: '1px solid #e3e3e3',
    display: 'flex',
  },
  detailRowLabel: {
    fontWeight: 500,
    width: 180,
  },
  detailRowValue: {
    flex: 1,
  },
  ymalTitle: {
    textAlign: 'center',
  },
  ymalProductCards: ({ mq }) => ({
    margin: 'auto',
    columnGap: '16px',
    display: 'grid',
    gridTemplateColumns: mq.down.lg ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
    rowGap: '16px',
  }),
}))
