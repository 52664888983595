import qs from 'querystring'

export const useGuid = () => {
  if (process.env.REACT_APP_MOCK) {
    return null
  }

  const guid =
    qs.parse(document.location.search.slice(1))['biceps-guid'] ||
    document.getElementsByTagName('biceps-host')[0].getAttribute('data-guid')

  return guid
}
