import { FC, useCallback, useContext } from 'react'
import NativeButton from '../../../components/NativeButton'
import { BrandContext } from '../../../core/hooks/useBrand'
import { GlobalOptionsContext } from '../../../core/hooks/useGlobalOptions'
import { useImageParams } from '../../../core/hooks/useImageParams'
import { useMediaQuery } from '../../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

export type ProductCardProps = {
  codePim: string
  findOutMore?: string
  image: string
  name: string
  novelty: boolean
  onClick: (Args: { reference: string; codePim: string }) => void
  reference: string
  searchAfter?: string
  showReference?: boolean
  premiumName: string
  collection: string
  nameLogic?: string[]
}

const ProductCardView: FC<ProductCardProps> = props => {
  const { getImageParams } = useImageParams()
  const { mq } = useMediaQuery()
  const brand = useContext(BrandContext)
  const options = useContext(GlobalOptionsContext)

  const renderName = useCallback(() => {
    let title = ''

    props.nameLogic?.forEach(logic => {
      if (title !== '') return

      if (logic === 'article_name' && props.name) {
        title = props.name
      } else if (logic === 'article_premium_name' && props.premiumName) {
        title = props.premiumName
      } else if (
        logic === 'collection' &&
        props.collection &&
        props.premiumName
      ) {
        title = `${props.collection} ${props.premiumName}`
      }
    })

    return title.toUpperCase()
  }, [props.collection, props.name, props.premiumName, props.nameLogic])

  const classes = useStyles({ brand, options })

  return (
    <div
      className={classes.root}
      draggable={false}
      onClick={() =>
        props.onClick &&
        props.onClick({ reference: props.reference, codePim: props.codePim })
      }
      onKeyPress={e => {
        e.key === 'Enter' &&
          props.onClick &&
          props.onClick({ reference: props.reference, codePim: props.codePim })
      }}
      tabIndex={0}
      role="button"
    >
      <div className={classes.imageWrapper}>
        {props.novelty && (
          <div className={classes.novelty}>{props.novelty}</div>
        )}
        <div className={classes.image}>
          <img
            src={`${props.image}${getImageParams(480)}`}
            alt={props.reference}
            draggable={false}
          />
        </div>
      </div>
      <div className={classes.info}>
        <span className={classes.title}>
          {brand === 'bem' ? (
            <>
              <span className={classes.bemTitleCollection}>
                {props.collection}
              </span>
              <span className={classes.bemTitleReference}>
                {' '}
                {props.premiumName?.toUpperCase()}
              </span>
            </>
          ) : (
            renderName()
          )}
        </span>
        {props.showReference && (
          <span className={classes.reference}>{props.reference}</span>
        )}
        {mq.up.md && (
          <NativeButton className={classes.findOutMoreButton} tabIndex={-1}>
            {props.findOutMore?.toUpperCase()}
          </NativeButton>
        )}
        {/* <span className={classes.price}>€ 30,000</span> */}
      </div>
    </div>
  )
}

export default ProductCardView
