import axios from 'axios'
import { FC, useContext, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import Button from '../../components/Button'
import InputField from '../../components/InputField'
import NativeButton from '../../components/NativeButton'
import SelectField from '../../components/SelectField'
import { Contact, ContactFormType } from '../../core/hooks/useComposition'
import { ContactsContext } from '../../core/hooks/useContacts'
import { useGuid } from '../../core/hooks/useGuid'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { Nullable } from '../../core/tools/utilityTypes'
import { useStyles } from './styles'
import { BrandContext } from '../../core/hooks/useBrand'

type Props = {
  component: ContactFormType
}

const ContactForm: FC<Props> = ({ component }) => {
  const { mq } = useMediaQuery()
  const contacts = useContext(ContactsContext)
  const brand = useContext(BrandContext)
  const guid = useGuid()

  const [isExtended, setIsExtended] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSent, setHasSent] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [messageKey, setMessageKey] = useState('')
  const [contact, setContact] = useState<Nullable<Contact>>(
    contacts && contacts[0]
  )
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')

  useUpdateEffect(() => {
    setContact(contacts && contacts[0])
  }, [contacts])

  const classes = useStyles({ mq, hasSent })

  return (
    <div className={classes.root}>
      <Button
        classes={{ button: classes.formButton }}
        onClick={() => setIsExtended(!isExtended)}
      >
        {component.content.ctaLabel}
      </Button>
      {isExtended && (
        <form
          className={classes.form}
          onSubmit={event => {
            event.preventDefault()

            if (isSubmitting) return

            setIsSubmitting(true)
            setHasSent(false)
            setHasFailed(false)

            const body = {
              comments: message,
              contact,
              email,
              firstName,
              lastName,
              phoneNumber,
              showcaseGuid: guid,
            }

            axios
              .post(`${process.env.REACT_APP_API_URL}/v1/contact`, body)
              .then(response => {
                setMessageKey(response.data.message)
                setHasSent(true)
              })
              .catch(error => {
                setMessageKey(error.response.data.message)
                setHasFailed(true)
              })
              .finally(() => {
                setIsSubmitting(false)
              })
          }}
        >
          <div
            className={classes.fields}
            style={{ fontSize: brand === 'bem' ? 16 : undefined }}
          >
            <InputField
              label={`${component.keywords.firstname} *`}
              name="firstname"
              defaultValue={firstName}
              onChange={value => {
                setFirstName(value)
              }}
            />
            <InputField
              label={`${component.keywords.lastname} *`}
              name="lastname"
              defaultValue={lastName}
              onChange={value => {
                setLastName(value)
              }}
            />
            <InputField
              label={`${component.keywords.email} *`}
              name="email"
              type="email"
              defaultValue={email}
              onChange={value => {
                setEmail(value)
              }}
            />
            <InputField
              label={`${component.keywords.phone_number} *`}
              name="phone"
              type="tel"
              defaultValue={phoneNumber}
              onChange={value => {
                setPhoneNumber(value)
              }}
            />
          </div>
          <div
            className={classes.fullwidthField}
            style={{ fontSize: brand === 'bem' ? 16 : undefined }}
          >
            <SelectField
              defaultValue={contact?.name}
              label={`${component.keywords.contact} *`}
              name="contact"
              options={contacts?.map(c => c.name)}
              onChange={value =>
                setContact(contacts?.find(c => value === c.name) || contact)
              }
            />
          </div>
          <div
            className={classes.fullwidthField}
            style={{ fontSize: brand === 'bem' ? 16 : undefined }}
          >
            <InputField
              label={`${component.keywords.message} *`}
              name="message"
              textarea
              defaultValue={message}
              onChange={value => {
                setMessage(value)
              }}
            />
          </div>
          {(hasSent || hasFailed) && (
            <div className={classes.message}>
              {component.keywords[messageKey]}
            </div>
          )}
          <div className={classes.CtaContainer}>
            {contacts?.some(c => c.phoneNumber) ? (
              <div>
                {`${component.keywords.call_us}`}
                {contacts?.map((c, i) => (
                  <a
                    key={i}
                    href={`tel: ${c.phoneNumber}`}
                    className={classes.contact}
                  >
                    {c.phoneNumber}
                  </a>
                ))}
              </div>
            ) : (
              <div></div>
            )}
            <NativeButton disabled={isSubmitting} type="submit">
            {`${component.keywords.send}`}
            </NativeButton>
          </div>
        </form>
      )}
    </div>
  )
}

export default ContactForm
