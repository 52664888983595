import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: 'inherit',
    fontWeight: 600,
    margin: 8,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
})
