import clsx from 'clsx'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import NativeButton from '../../../components/NativeButton'
import { BrandContext } from '../../../core/hooks/useBrand'
import { FiltersContext } from '../../../core/hooks/useFilters'
import { GlobalOptionsContext } from '../../../core/hooks/useGlobalOptions'
import { useMediaQuery } from '../../../core/hooks/useMediaQuery'
import FilterView from '../FilterView'
import { FilterViewProps } from '../FilterView/FilterView'
import { useStyles } from './styles'

export type FilterGroupProps = {
  displayName: string
  filters: FilterViewProps[]
  key: string
  keyCode: string
  onToggleFilter: (arg: { key: string; id: string }) => void
}

const FilterGroupView: FC<FilterGroupProps> = props => {
  const [isExtended, setIsExtended] = useState(false)
  const isEmpty = useMemo(() => !props.filters?.length, [props.filters?.length])
  const filters = useContext(FiltersContext)
  const options = useContext(GlobalOptionsContext)
  const brand = useContext(BrandContext)
  const { mq } = useMediaQuery()

  const hasActiveFilters = useMemo(
    () => !!filters.params.find(f => f.key === props.keyCode),
    [filters.params, props.keyCode]
  )

  const onClick = () => {
    !isEmpty && setIsExtended(!isExtended)
  }

  useEffect(() => {
    hasActiveFilters && setIsExtended(true)
  }, [hasActiveFilters])

  const classes = useStyles({ isExtended, isEmpty, mq, options })

  return (
    <div className={classes.root}>
      <NativeButton
        className={classes.button}
        noBorder
        noUppercase
        onClick={onClick}
        disabled={isEmpty}
      >
        <span className={clsx({ [classes.bemCategoryTitle]: brand === 'bem' })}>
          {props.displayName.toUpperCase()}
        </span>
      </NativeButton>
      <div className={classes.filters}>
        {isExtended &&
          props.filters?.map(filter => (
            <FilterView
              key={filter.id}
              {...filter}
              onClick={id => {
                props.onToggleFilter &&
                  props.onToggleFilter({ key: props.keyCode, id })
              }}
            />
          ))}
      </div>
    </div>
  )
}

export default FilterGroupView
