import { createUseStyles } from 'react-jss'
import { MQ } from '../../../core/hooks/useMediaQuery'

type Props = {
  mq: MQ
}

export const useStyles = createUseStyles<string, Props>({
  root: ({ mq }) => ({
    width: mq.up.md && 320,
  }),
  wrapper: ({ mq }) => ({
    padding: mq.up.md && '0 16px 16px 0',
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  label: {
    margin: 0,
  },
  searchInputWrapper: ({ mq }) => ({
    padding: '8px',
    paddingRight: mq.up.md ? 0 : '8px',
    borderBottom: '1px solid #dbdbdb',
  }),
  searchInput: ({ mq }) => ({
    border: 'none',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontSize: '14px',
    height: '40px',
    letterSpacing: 'inherit',
    paddingLeft: mq.up.md && '16px',
    textAlign: !mq.up.md && 'center',
    width: '100%',
  }),
})
