import { FC } from 'react'
import Title from '../../components/Title'
import { BannerType } from '../../core/hooks/useComposition'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

type Props = {
  component: BannerType
}

const Banner: FC<Props> = ({ component }) => {
  const { mq } = useMediaQuery()

  const classes = useStyles({ ...component.options, mq })

  return (
    <div className={classes.root}>
      <div
        aria-label={component.content.title || ''}
        className={classes.image}
        role="img"
        style={{ backgroundImage: `url(${component.content.image.src1x})` }}
        title={component.content.image.alt || component.content.title}
      >
        {component.content.title && (
          <Title className={classes.title} variant="h1">
            {component.content.title}
          </Title>
        )}
      </div>
    </div>
  )
}

export default Banner
