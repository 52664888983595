import { FC, useContext } from 'react'
import Title from '../../components/Title'
import { TitleParagraphType } from '../../core/hooks/useComposition'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'
import { BrandContext } from '../../core/hooks/useBrand'

type Props = {
  component: TitleParagraphType
}

const TitleParagraph: FC<Props> = ({ component }) => {
  const { mq } = useMediaQuery()
  const brand = useContext(BrandContext)

  const classes = useStyles({
    mq,
    styles: component.options.styles,
  })

  return (
    <div className={classes.root}>
      {component.content.title && <Title>{component.content.title}</Title>}
      {component.content.paragraph && (
        <p style={{ fontSize: brand === 'bem' ? 16 : undefined }}>
          {component.content.paragraph}
        </p>
      )}
    </div>
  )
}

export default TitleParagraph
