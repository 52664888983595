import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    minHeight: 80,
    margin: '16px',
    '& svg': {
      width: '100%',
    },
  },
  link: {
    margin: 'auto',
  },
})
