import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {},
  image: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
  },
})
