import clsx from 'clsx'
import qs from 'querystring'
import { FC, useContext } from 'react'
import Title from '../../components/Title'
import { GenderFilterType } from '../../core/hooks/useComposition'
import { FiltersContext } from '../../core/hooks/useFilters'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

type Props = {
  component: GenderFilterType
}

const GenderFilter: FC<Props> = ({ component }) => {
  const filters = useContext(FiltersContext)
  const { mq } = useMediaQuery()

  const onGenderClick = (parsableFilter: string) => {
    const filter = qs.parse(parsableFilter)
    const key = Object.keys(filter)[0]
    const id = filter[key] as string

    filters.toggle({ key, id }, { withReset: true })
  }

  const onManClick = () => {
    onGenderClick(component.content.manFilter)
  }

  const onWomanClick = () => {
    onGenderClick(component.content.womanFilter)
  }

  const classes = useStyles({ ...component.options, mq })

  const manRender = (
    <div
      className={classes.insert}
      onClick={onManClick}
      style={{ backgroundImage: `url(${component.content.manImage.src1x})` }}
      tabIndex={0}
    >
      <Title className={clsx(classes.title, classes.manTitle)} variant="h3">
        {component.content.manTitle}
      </Title>
    </div>
  )

  const womanRender = (
    <div
      className={classes.insert}
      onClick={onWomanClick}
      style={{ backgroundImage: `url(${component.content.womanImage.src1x})` }}
      tabIndex={0}
    >
      <Title className={clsx(classes.title, classes.womanTitle)} variant="h3">
        {component.content.womanTitle}
      </Title>
    </div>
  )

  return (
    <div className={classes.root}>
      {component.options.arrangement === 1 && womanRender}
      {manRender}
      {component.options.arrangement === 2 && womanRender}
    </div>
  )
}

export default GenderFilter
