import clsx from 'clsx'
import { FC, useCallback, useContext, useRef, useState } from 'react'
import { useUpdateEffect, useWindowScroll } from 'react-use'
import NativeButton from '../../../components/NativeButton'
import Title from '../../../components/Title'
import { BrandContext } from '../../../core/hooks/useBrand'
import { CatalogueType, Product } from '../../../core/hooks/useComposition'
import { GlobalOptionsContext } from '../../../core/hooks/useGlobalOptions'
import { useImageParams } from '../../../core/hooks/useImageParams'
import { useMediaQuery } from '../../../core/hooks/useMediaQuery'
import { Undefinable } from '../../../core/tools/utilityTypes'
import { useStyles } from './styles'

type Props = Partial<{
  product: Product
  options: CatalogueType['options']
  keywords: { [key: string]: string }
  isTechnicalDetailsVisible: boolean
  onToggleTechnicalDetails: () => void
}>

const ProductShowcaseView: FC<Props> = ({ product, options, ...props }) => {
  const imageRef = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)
  const masonryRef = useRef<HTMLDivElement>(null)
  const { y } = useWindowScroll()
  const [infoTop, setInfoTop] = useState(0)
  const [scrollTimeout, setScrollTimeout] =
    useState<Undefinable<NodeJS.Timeout>>(undefined)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const { getImageParams } = useImageParams()
  const { mq } = useMediaQuery()
  const brand = useContext(BrandContext)
  const globalOptions = useContext(GlobalOptionsContext)

  useUpdateEffect(() => {
    if (mq.down.lg) return

    const newInfoTop = infoRef.current?.getBoundingClientRect().top || 0
    const infoHeight = infoRef.current?.offsetHeight || 0
    const masonryHeight = masonryRef.current?.offsetHeight || 0
    const maxOffset = infoHeight - masonryHeight

    scrollTimeout && clearTimeout(scrollTimeout)
    setScrollTimeout(
      setTimeout(() => {
        setInfoTop(newInfoTop > maxOffset ? newInfoTop : maxOffset)
      }, 50)
    )
  }, [y])

  useUpdateEffect(() => {
    setCurrentImageIndex(0)
  }, [product?.referenceDetail.reference])

  const classes = useStyles({
    mq,
    infoTop,
    globalOptions,
    brand,
  })

  const imageRender = (
    <div className={classes.imageWrapper}>
      <div
        ref={imageRef}
        className={classes.image}
        role="img"
        title={product?.referenceDetail.title}
        style={{
          backgroundImage: `url(${
            product?.sortedImages[currentImageIndex]
          }${getImageParams(800)})`,
        }}
      >
        <span className={classes.novelty}>
          {product?.referenceDetail.novelty?.toUpperCase()}
        </span>
      </div>
    </div>
  )

  const renderName = useCallback(() => {
    if (!options || !product) return

    let title = ''
    const { nameLogic } = options
    const detail = product.referenceDetail

    nameLogic.forEach(logic => {
      if (title !== '') return

      if (logic === 'article_name' && detail.title) {
        title = detail.title
      } else if (logic === 'article_premium_name' && detail.premiumName) {
        title = detail.premiumName
      } else if (
        logic === 'collection' &&
        detail.collection &&
        detail.premiumName
      ) {
        title = `${detail.collection} ${detail.premiumName.toUpperCase()}`
      }
    })

    return <Title className={classes.title}>{title}</Title>
  }, [classes, options, product])

  const renderDescription = useCallback(() => {
    if (!options || !product) return

    let description = ''
    const { descriptionLogic } = options
    const detail = product.referenceDetail

    descriptionLogic.forEach(logic => {
      if (description !== '') return

      if (logic === 'short_description' && detail.shortDescription) {
        description = detail.shortDescription
      } else if (logic === 'long_description' && detail.description) {
        description = detail.description
      } else if (logic === 'premium_description' && detail.premiumDescription) {
        description = detail.premiumDescription
      } else if (
        logic === 'premium_specific_highlight' &&
        detail.premiumSpecificHighlights
      ) {
        description = detail.premiumSpecificHighlights
      } else if (
        logic === 'premium_short_description' &&
        detail.premiumShortDescription &&
        detail.premiumDescription
      ) {
        description = logic
      }
    })

    return description === 'premium_short_description' ? (
      <>
        <p className={classes.bemDescription}>
          {detail.premiumShortDescription}
        </p>
        <p className={classes.bemDescription}>{detail.premiumDescription}</p>
      </>
    ) : (
      <p className={classes.description}>{description}</p>
    )
  }, [classes, options, product])

  return (
    <div className={classes.root}>
      <div className={classes.masonry} ref={masonryRef}>
        {!mq.up.md && imageRender}
        <div className={classes.thumbnails}>
          {product?.sortedImages.map((image, index) => (
            <div
              key={index}
              className={clsx(classes.thumbnail)}
              role="button"
              tabIndex={0}
              title={`${product.referenceDetail.title} ${index + 1}`}
              onMouseDown={() => {
                setCurrentImageIndex(index)
              }}
              onKeyPress={e => {
                e.key === 'Enter' && setCurrentImageIndex(index)
              }}
              style={{
                backgroundImage: `url(${image}${getImageParams(320)})`,
              }}
            ></div>
          ))}
        </div>
        {mq.up.md && imageRender}
      </div>
      <div className={classes.infoOutterWrapper} ref={infoRef}>
        <div className={classes.infoInnerWrapper}>
          {brand === 'bem' ? (
            <Title className={classes.title}>
              <span className={classes.bemTitleCollection}>
                {product?.referenceDetail.collection}
              </span>
              <span className={classes.bemTitleReference}>
                {' '}
                {product?.referenceDetail.premiumName}
              </span>
            </Title>
          ) : (
            renderName()
          )}
          {options?.overview.reference && (
            <p className={classes.reference}>
              {product?.referenceDetail.reference}
            </p>
          )}
          {options?.overview.internalReference && (
            <p className={classes.reference}>
              {product?.referenceDetail.internalReference}
            </p>
          )}
          {options?.overview.target && <p>{product?.referenceDetail.target}</p>}
          {options?.overview.limitedEdition &&
            product?.referenceDetail.limitedEdition && (
              <p>{props.keywords?.['limited_edition']}</p>
            )}
          {(options?.overview.collection ||
            options?.overview.subCollection) && (
            <p className={classes.collection}>
              {options.overview.collection &&
                product?.referenceDetail.collection}
              {options.overview.collection &&
                options.overview.subCollection &&
                product?.referenceDetail.collection &&
                product?.referenceDetail.subCollection &&
                ' - '}
              {options.overview.subCollection &&
                product?.referenceDetail.subCollection}
            </p>
          )}
          {brand === 'bem' ? (
            <>
              <p className={classes.bemDescription}>
                {product?.referenceDetail.premiumShortDescription}
              </p>
              <p className={classes.bemDescription}>
                {product?.referenceDetail.premiumDescription}
              </p>
            </>
          ) : (
            renderDescription()
          )}
          {options?.overview.stoneDisclaimer &&
            product?.referenceDetail.details.some(
              detail => detail.key === 'stones'
            ) && (
              <p
                className={classes.description}
                style={{ fontStyle: 'italic' }}
              >
                {props.keywords?.['stone_disclaimer']}
              </p>
            )}
          {options?.technicalDetails.length ? (
            <NativeButton
              className={classes.showMoreButton}
              onClick={props.onToggleTechnicalDetails}
            >
              {
                props.keywords?.[
                  props.isTechnicalDetailsVisible
                    ? 'hide_technical_details'
                    : 'show_technical_details'
                ]
              }
            </NativeButton>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProductShowcaseView
