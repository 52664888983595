import { createUseStyles } from 'react-jss'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = { mq: MQ }

export const useStyles = createUseStyles({
  root: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  carousel: ({ mq }: Props) => {
    let gridAutoColumns = 'calc(25% - 12px)'

    if (mq.down.xl) {
      gridAutoColumns = 'calc(33% - 8px)'
    }

    if (mq.down.lg) {
      gridAutoColumns = 'calc(50% - 8px)'
    }

    return {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: 16,
      gridAutoColumns,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        height: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#f9f9f9',
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          background: '#949494',
        },
      },
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  image: {
    aspectRatio: '2/3',
    display: 'block',
    objectFit: 'cover',
    width: '100%',
  },
  title: {
    textAlign: 'center',
  },
})
