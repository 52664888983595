import preset from 'jss-preset-default'
import ReactDOM from 'react-dom'
import { jss, JssProvider } from 'react-jss'
import App from './App'

const root = (document
  .querySelector('biceps-host')
  ?.shadowRoot?.querySelector('biceps-showcase') ||
  document.querySelector('biceps-showcase')) as HTMLElement

const ga = document.createElement('script')
ga.src = '//www.googletagmanager.com/gtm.js?id=GTM-TQCPHR6'
ga.async = true
document.head.append(ga)

jss.setup({ ...preset(), insertionPoint: root })

ReactDOM.render(
  <JssProvider jss={jss}>
    <App />
  </JssProvider>,
  root
)
