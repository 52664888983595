import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {},
  label: {
    display: 'block',
  },
  input: {
    height: '32px',
    width: '100%',
  },
})
