declare global {
  interface Window {
    dataLayer: DataLayer[]
  }
}

type DataLayer = {
  event: 'bcps-virtualPageview'
  uri: string
  partner: string
  brand: string
  pgcountry: string
  pglanguage: string
}

export const useDataLayer = () => {
  const dataLayer: DataLayer[] & { push: (dataLayer: DataLayer) => void } =
    window.dataLayer || []

  return { dataLayer }
}
