import { createUseStyles } from 'react-jss'
import { MQ } from '../../../core/hooks/useMediaQuery'
import { jssTheme } from '../../../core/theme'

type Keys = 'root' | 'totalWrapper' | 'total' | 'cards'

type Props = {
  mq: MQ
}

export const useStyles = createUseStyles<Keys, Props, typeof jssTheme>(
  theme => ({
    root: ({ mq }) => ({
      width: `calc(100% - ${mq.up.md ? '320px' : '0px'})`,
    }),
    totalWrapper: {
      alignItems: 'center',
      display: 'flex',
      height: 38,
      marginBottom: '16px',
    },
    total: {
      fontSize: '14px',
    },
    cards: ({ mq }) => ({
      columnGap: '16px',
      display: 'grid',
      gridTemplateColumns: mq.down.xl
        ? mq.down.md
          ? 'repeat(1, 1fr)'
          : 'repeat(3, 1fr)'
        : 'repeat(4, 1fr)',
      rowGap: '16px',
    }),
  })
)
