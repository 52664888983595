import clsx from 'clsx'
import { ButtonHTMLAttributes, FC, useContext } from 'react'
import { GlobalOptionsContext } from '../../core/hooks/useGlobalOptions'
import { useStyles } from './styles'

type Props = Partial<{
  className: string
  disabled?: boolean
  noBorder?: boolean
  noUppercase?: boolean
  onClick: () => void
  href: string
  type: ButtonHTMLAttributes<HTMLButtonElement>['type']
  tabIndex: number
}>

const Button: FC<Props> = ({
  children,
  noBorder,
  noUppercase,
  className,
  disabled,
  onClick,
  type,
  ...props
}) => {
  const options = useContext(GlobalOptionsContext)

  const classes = useStyles({ noBorder, noUppercase, disabled, options })

  return props.href ? (
    <a
      className={clsx(classes.root, className)}
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  ) : (
    <button
      className={clsx(classes.root, className)}
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
