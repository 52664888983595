import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {
    marginTop: '16px',
    marginBottom: '16px',
    paddingBottom: '56.25%',
    height: 0,
    position: 'relative',
    '& iframe': {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
})
