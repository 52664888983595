import { FC, useContext } from 'react'
import { PagesContext } from '../../core/hooks/usePages'
import { Link } from 'react-router-dom'
import { useStyles } from './styles'

type Props = {}

const Menu: FC<Props> = props => {
  const pages = useContext(PagesContext)

  const classes = useStyles()

  return pages?.filter(p => p.showInMenu).length ? (
    <div className={classes.root}>
      {pages
        ?.filter(p => p.showInMenu)
        .map((p, i) => (
          <Link key={i} className={classes.link} to={p.path}>
            {p.title}
          </Link>
        ))}
    </div>
  ) : null
}

export default Menu
