import { useOcp } from './useOcp'

type OcpWidth = 320 | 480 | 800 | 1200 | 3000

export const useImageParams = () => {
  const ocp = useOcp()

  return {
    getImageParams: (width: OcpWidth) =>
      `${ocp ? '&' : '?'}impolicy=resize&width=${width}`,
  }
}
