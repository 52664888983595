import { FC } from 'react'
import { Link } from 'react-router-dom'
import Title from '../../components/Title'
import { CollectionMenuType } from '../../core/hooks/useComposition'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

type Props = {
  component: CollectionMenuType
}

const CollectionMenu: FC<Props> = ({ component }) => {
  const { mq } = useMediaQuery()

  const classes = useStyles({ mq })

  return (
    <div className={classes.root}>
      <div
        className={classes.carousel}
        style={{
          justifyContent:
            mq.up.md && component.content.length < 4
              ? 'center'
              : mq.up.sm && component.content.length < 3
              ? 'center'
              : undefined,
        }}
      >
        {component.content.map((collection, index) => (
          <Link
            key={index}
            className={classes.link}
            to={collection.link.url}
            tabIndex={0}
          >
            <img
              className={classes.image}
              srcSet={`${collection.image.src1x} 1x, ${
                collection.image.src2x || collection.image.src1x
              } 2x`}
              title={collection.title}
              alt={collection.title}
            />
            <Title className={classes.title} variant="h3">
              {collection.title}
            </Title>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default CollectionMenu
