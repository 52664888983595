import qs from 'querystring'
import { FC, useContext } from 'react'
import { useMeasure } from 'react-use'
import { CollectionFilterType } from '../../core/hooks/useComposition'
import { FiltersContext } from '../../core/hooks/useFilters'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { useStyles } from './styles'

type Props = {
  component: CollectionFilterType
  language?: string
}

const CollectionFilter: FC<Props> = ({ component, language }) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const { mq } = useMediaQuery()
  const filters = useContext(FiltersContext)

  const classes = useStyles()

  return (
    <div className={classes.root} ref={ref}>
      <div
        className={classes.carousel}
        style={{
          justifyContent:
            mq.up.md && component.content.collections.length < 5
              ? 'center'
              : mq.up.sm && component.content.collections.length < 3
              ? 'center'
              : undefined,
        }}
      >
        {component.content.collections.map((collection, index) => (
          <div
            key={index}
            className={classes.item}
            onClick={_ => {
              const filter = qs.parse(collection.collectionFilter)
              const key = Object.keys(filter)[0]
              const id = filter[key] as string
              const param = { key, id }

              filters.toggle(param, {
                withReset: true,
              })
            }}
            style={{
              flex: `0 0 ${width / (mq.up.md ? 5 : mq.up.sm ? 3 : 1)}px`,
            }}
            tabIndex={0}
          >
            <img
              className={classes.image}
              src={`${collection.image.src1x}${
                collection.image.src1x.includes('?') ? '&' : '?'
              }impolicy=resize&height=560`}
              alt={
                collection.translations.find(t => t.language === language)?.name
              }
              style={{ width: 280 }}
            />
            <p className={classes.subtitle}>{component.keywords?.collection}</p>
            <p className={classes.title}>
              {collection.translations.find(t => t.language === language)?.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CollectionFilter
