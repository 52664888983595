import axios from 'axios'
import clsx from 'clsx'
import { FC, useCallback, useContext, useRef, useState } from 'react'
import { useMeasure, useMount, useUpdateEffect } from 'react-use'
import NativeButton from '../../components/NativeButton'
import Title from '../../components/Title'
import { BrandContext } from '../../core/hooks/useBrand'
import { CatalogueType, Product } from '../../core/hooks/useComposition'
import { FiltersContext } from '../../core/hooks/useFilters'
import { GlobalOptionsContext } from '../../core/hooks/useGlobalOptions'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { Undefinable } from '../../core/tools/utilityTypes'
import { FilterGroupProps } from './FilterCategoryView/FilterCategoryView'
import FiltersView from './FiltersView'
import ProductCardView from './ProductCardView'
import { ProductCardProps } from './ProductCardView/ProductCardView'
import ProductGridView from './ProductGridView'
import ProductShowcaseView from './ProductShowcaseView'
import { useStyles } from './styles'

type Props = Partial<{
  component: CatalogueType
  language: string
}>

const Catalogue: FC<Props> = ({ component, language }) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [codePim, setCodePim] = useState<string>()
  const [filterCategoryPool, setFilterCategoryPool] = useState<
    FilterGroupProps[]
  >([])
  const [products, setProducts] = useState<Omit<ProductCardProps, 'onClick'>[]>(
    []
  )
  const [total, setTotal] = useState(0)
  const [searchAfterIndex, setSearchAfterIndex] = useState(-1)
  const [searchAfters, setSearchAfters] = useState<string[]>([])
  const [product, setProduct] = useState<Product>()
  const [catalogueRef, { height: catalogueHeight }] =
    useMeasure<HTMLDivElement>()
  const [productRef, { height: productHeight }] = useMeasure<HTMLDivElement>()
  const filters = useContext(FiltersContext)
  const [term, setTerm] = useState('')
  const [fetchOnTerm, setFetchOnTerm] = useState(true)
  const [termTimeout, setTermTimeout] =
    useState<Undefinable<NodeJS.Timeout>>(undefined)
  const [isTechnicalDetailsVisible, setIsTechnicalDetailsVisible] =
    useState(false)

  const brand = useContext(BrandContext)
  const globalOptions = useContext(GlobalOptionsContext)
  const { mq } = useMediaQuery()

  useMount(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/catalogue/${
          component?.content.assortment
        }/search?language=${language}${
          component?.content.classification
            ? `&classification=${component?.content.classification}`
            : ''
        }&PageSize=12`
      )
      .then(res => {
        setProducts(res.data.results)
        setTotal(res.data.total)
        setFilterCategoryPool(res.data.filterGroups)
      })
  })

  const smoothScroll = useCallback(() => {
    document.getElementsByTagName('biceps-host')[0]?.shadowRoot
      ? document
          .getElementsByTagName('biceps-host')[0]
          ?.shadowRoot?.getElementById('biceps__catalogue')
          ?.scrollIntoView()
      : document.getElementById('biceps__catalogue')?.scrollIntoView()
  }, [])

  const resetSearchAfter = () => {
    setSearchAfterIndex(-1)
    setSearchAfters([])
  }

  const fetchUrl = `${process.env.REACT_APP_API_URL}/v1/catalogue/${
    component?.content.assortment
  }/search?language=${language}${
    component?.content.classification
      ? `&classification=${component?.content.classification}`
      : ''
  }&PageSize=12${filters.params.length > 0 ? '&' : ''}${filters.params
    .map(filterParam => `${filterParam.key}=${filterParam.id}`)
    .join('&')}${term && `&term=${term}`}`

  const fetchWithFilters = () => {
    setProducts([])
    setCodePim(undefined)

    axios.get(fetchUrl).then(res => {
      setProducts(res.data.results)
      setTotal(res.data.total)
      setFilterCategoryPool(res.data.filterGroups)
      smoothScroll()
    })
  }

  const fetchWithSearchAfter = () => {
    if (searchAfterIndex === -1) return

    setProducts([])
    setCodePim(undefined)

    axios
      .get(
        fetchUrl.concat(
          `${
            searchAfters[searchAfterIndex]
              ? `&SearchAfter=${searchAfters[searchAfterIndex]}`
              : ''
          }`
        )
      )
      .then(res => {
        setProducts(res.data.results)
        setTotal(res.data.total)
        setFilterCategoryPool(res.data.filterGroups)
        smoothScroll()
      })
  }

  const updateSearchAfter = () => {
    const newSearchAfters = [...searchAfters]
    const searchAfter = products[products.length - 1]?.searchAfter

    searchAfter && newSearchAfters.push(searchAfter)
    setSearchAfters(newSearchAfters)
  }

  const fetchProduct = () => {
    if (codePim && codePim !== product?.referenceDetail.codePim) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/catalogue/${
            component?.content.assortment
          }/${codePim}?related=${
            component?.options.ymal.enabled
          }&language=${language}${
            component?.content.classification
              ? `&classification=${component?.content.classification}`
              : ''
          }`
        )
        .then(res => {
          codePim === res.data.referenceDetail.codePim && setProduct(res.data)
          smoothScroll()
        })
    }
  }

  const onTermChangeEffect = () => {
    termTimeout && clearTimeout(termTimeout)
    fetchOnTerm &&
      setTermTimeout(
        setTimeout(() => {
          fetchWithFilters()
        }, 500)
      )
    setFetchOnTerm(true)
  }

  useUpdateEffect(resetSearchAfter, [filters.params, term])
  useUpdateEffect(fetchWithFilters, [filters.params])
  useUpdateEffect(onTermChangeEffect, [term])
  useUpdateEffect(fetchWithSearchAfter, [searchAfterIndex])
  useUpdateEffect(updateSearchAfter, [products])
  useUpdateEffect(fetchProduct, [codePim, product?.referenceDetail.codePim])

  const classes = useStyles({
    catalogueHeight,
    mq,
    productHeight,
    reference: codePim,
    showTechnicalDetails: isTechnicalDetailsVisible,
    globalOptions,
    brand,
  })

  return (
    <div className={classes.root} ref={rootRef} id="biceps__catalogue">
      <div className={classes.wrapper}>
        <div className={classes.catalogue}>
          <div ref={catalogueRef}>
            <div className={classes.filtersAndProducts}>
              <FiltersView
                keywords={component?.keywords}
                onReset={() => {
                  setFetchOnTerm(false)
                  setTerm('')
                  termTimeout && clearTimeout(termTimeout)
                  filters.reset()
                }}
                searchTerm={term}
                onSearch={e => {
                  setTerm(e.currentTarget.value)
                }}
                filterCategories={component?.options.filters}
                filterCategoryPool={filterCategoryPool}
                onFilter={filter => {
                  filters.toggle(filter)
                }}
              />
              <ProductGridView
                cardsKey={filters.params.join()}
                keywords={component?.keywords}
                onCardClick={({ codePim }) => {
                  setCodePim(codePim)
                }}
                products={products}
                showReferences={component?.options.productCard.reference}
                total={total}
                options={component?.options}
              />
            </div>
            <div className={classes.paging}>
              <NativeButton
                className={classes.previousButton}
                disabled={searchAfterIndex === -1}
                onClick={() => {
                  setSearchAfterIndex(searchAfterIndex - 1)
                  searchAfterIndex === 0 && fetchWithFilters()
                }}
              >
                {component?.keywords.previous}
              </NativeButton>
              <NativeButton
                disabled={(searchAfterIndex + 2) * 12 >= total}
                onClick={() => {
                  setSearchAfterIndex(searchAfterIndex + 1)
                }}
              >
                {component?.keywords.next}
              </NativeButton>
            </div>
          </div>
        </div>
        <div className={classes.productDetail}>
          <div ref={productRef}>
            <NativeButton
              className={classes.backButton}
              onClick={() => {
                setCodePim(undefined)
                setIsTechnicalDetailsVisible(false)
              }}
            >
              {component?.keywords.back}
            </NativeButton>
            <ProductShowcaseView
              isTechnicalDetailsVisible={isTechnicalDetailsVisible}
              keywords={component?.keywords}
              options={component?.options}
              product={product}
              onToggleTechnicalDetails={() =>
                setIsTechnicalDetailsVisible(!isTechnicalDetailsVisible)
              }
            />
            {component?.options.technicalDetails.length ? (
              <div className={classes.technicalDetails}>
                <div className={classes.technicalDetailsWrapper}>
                  {component?.options.technicalDetails.map(tdo =>
                    product?.referenceDetail.details
                      .filter(d => d.key === tdo.key)
                      .map((ptd, index) =>
                        tdo.rows.length > 0 &&
                        tdo.rows.some(row =>
                          ptd.rows.find(r => r.key === row.key)
                        ) ? (
                          <div key={index} className={classes.detailWrapper}>
                            <Title
                              className={clsx(classes.detailLabel, {
                                [classes.bemDetailLabel]: brand === 'bem',
                              })}
                              variant="h4"
                            >
                              {ptd.label}
                            </Title>
                            {tdo.rows.map((row, rowIndex) => {
                              const ptdr = ptd.rows.filter(
                                r => r.key === row.key
                              )

                              return ptdr.length > 0 ? (
                                <div
                                  className={classes.detailRowWrapper}
                                  key={rowIndex}
                                >
                                  <div className={classes.detailRow}>
                                    {ptdr[0]?.label && (
                                      <p className={classes.detailRowLabel}>
                                        {ptdr[0].label}
                                      </p>
                                    )}
                                    <p className={classes.detailRowValue}>
                                      {ptdr.map((lol, index) => (
                                        <span
                                          key={index}
                                          style={{ display: 'block' }}
                                        >
                                          {lol.value}
                                        </span>
                                      ))}
                                    </p>
                                  </div>
                                </div>
                              ) : null
                            })}
                          </div>
                        ) : null
                      )
                  )}
                </div>
              </div>
            ) : null}
            {(component?.options.editorial.collectionDescription ||
              component?.options.editorial.subCollectionDescription) && (
              <div className={classes.productEditorial}>
                {component?.options.editorial.collectionDescription &&
                  product?.referenceDetail?.collectionDescription && (
                    <>
                      <Title variant="h3" className={classes.collectionTitle}>
                        {component?.keywords.about_collection}
                      </Title>
                      <p
                        className={classes.collectionDescription}
                        style={{ fontSize: brand === 'bem' ? 16 : undefined }}
                      >
                        {product?.referenceDetail?.collectionDescription}
                      </p>
                    </>
                  )}
                {component?.options.editorial.subCollectionDescription &&
                  product?.referenceDetail?.subCollectionDescription && (
                    <>
                      <Title variant="h3" className={classes.collectionTitle}>
                        {component?.keywords.about_subcollection}
                      </Title>
                      <p
                        className={classes.collectionDescription}
                        style={{ fontSize: brand === 'bem' ? 16 : undefined }}
                      >
                        {product?.referenceDetail?.subCollectionDescription}
                      </p>
                    </>
                  )}
              </div>
            )}
            {component?.options.ymal.enabled && (
              <div key={product?.referenceDetail.reference}>
                <Title className={classes.ymalTitle}>
                  {component?.keywords.you_may_also_like}
                </Title>
                <div className={classes.ymalProductCards}>
                  {product?.related.map((product, index) => (
                    <ProductCardView
                      key={index}
                      {...product}
                      showReference={component?.options.productCard.reference}
                      findOutMore={component?.keywords.find_out_more}
                      nameLogic={component.options.nameLogic}
                      onClick={({ codePim }) => {
                        setCodePim(codePim)
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Catalogue
