import axios, { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import mock from '../../mocks/default'
import { Nullable } from '../tools/utilityTypes'
import { GlobalOptions } from './useGlobalOptions'
import { useGuid } from './useGuid'

export type Brand =
  | 'pia'
  | 'iwc'
  | 'pan'
  | 'vac'
  | 'als'
  | 'mtb'
  | 'jlc'
  | 'bem'
  | 'rdu'

type ComponentBase = { keywords: { [key: string]: string } }

type Image = {
  src1x: string
  src2x?: string
  alt?: string
}

export type BannerType = ComponentBase & {
  template: 'banner'
  content: {
    image: Image
    title: string
  }
  options: {
    imageSize: number
    titleHue: number
    titleAlignment: [number, number]
  }
}

export type SpacerType = ComponentBase & {
  template: 'spacer'
  options: {
    desktopHeight: number
    tabletHeight: number
    mobileHeight: number
  }
}

export type CatalogueType = ComponentBase & {
  template: 'catalogue'
  content: {
    assortment: number
    classification?: number
  }
  options: {
    filters: string[]
    productCard: { reference: boolean }
    technicalDetails: {
      key: string
      rows: {
        key: string
      }[]
    }[]
    nameLogic: string[]
    descriptionLogic: string[]
    overview: {
      internalReference: boolean
      reference: boolean
      target: boolean
      collection: boolean
      subCollection: boolean
      limitedEdition: boolean
      stoneDisclaimer: boolean
    }
    editorial: {
      collectionDescription: boolean
      subCollectionDescription: boolean
    }
    ymal: { enabled: boolean }
  }
}

export type TitleParagraphType = ComponentBase & {
  template: 'title-paragraph'
  content: {
    title: string
    paragraph: string
  }
  options: {
    styles: {
      textAlign: number
    }
  }
}

export type MediaAndTextType = ComponentBase & {
  template: 'media-and-text'
  content: {
    ctaLabel: string
    ctaLink: {
      url: string
      isInternal: boolean
    }
    image: Image
    paragraph: string
    title: string
    video: {
      source: number
      url: string
    }
  }
  options: {
    mediaPosition: 1 | 2
    ratio: {
      width: number
      height: number
    }
  }
}

export type LogoType = ComponentBase & {
  template: 'logo'
  content: {
    brand: Nullable<Brand>
  }
}

export type GenderFilterType = ComponentBase & {
  template: 'gender-filter'
  content: {
    manTitle: string
    manImage: Image
    manFilter: string
    womanTitle: string
    womanImage: Image
    womanFilter: string
  }
  options: {
    arrangement: number
    manTitleHue: number
    womanTitleHue: number
  }
}

export type CollectionFilterType = ComponentBase & {
  template: 'collection-filter'
  content: {
    collections: {
      collectionFilter: string
      image: Image
      reference: string
      translations: {
        language: string
        name: string
      }[]
    }[]
  }
  options: {}
}

export type CollectionMenuType = ComponentBase & {
  template: 'collection-menu'
  content: {
    image: {
      src1x: string
      src2x: string
    }
    link: {
      isInternal: boolean
      url: string
    }
    title: string
  }[]
  options: {}
}

export type MediaPlayerType = ComponentBase & {
  template: 'media-player'
  content: {
    video: {
      source: number
      url: string
    }
  }
  options: {
    youtube: {
      autoplay: boolean
      hideControls: boolean
    }
  }
}

export type ContactFormType = ComponentBase & {
  template: 'contact-form'
  content: {
    ctaLabel: string
  }
}

export type SocialNetworkType = ComponentBase & {
  template: 'social-networks'
  content: {
    title: string
    facebook: string
    instagram: string
    twitter: string
    youtube: string
  }
}

export type CarouselType = ComponentBase & {
  template: 'carousel'
  content: { image: Image }[]
  options: {
    delay: number
    imageSize: number
  }
}

export type Product = {
  referenceDetail: {
    codePim: string
    brand: string
    collection: string
    subCollection: string
    collectionDescription: string
    shortDescription: string
    description: string
    premiumDescription: string
    premiumSpecificHighlights: string
    premiumShortDescription: string
    details: {
      label: string
      key: string
      rows: {
        code: string
        key: string
        label: string
        value: string
      }[]
    }[]
    novelty?: string
    productFamily: string
    productType: string
    internalReference: string
    reference: string
    subCollectionDescription: string
    target: string
    limitedEdition: boolean
    title: string
    premiumName: string
  }
  sortedImages: string[]
  related: {
    codePim: string
    image: string
    name: string
    novelty: boolean
    reference: string
    collection: string
    premiumName: string
  }[]
}

export type Contact = {
  name: string
  phoneNumber: string
}

export type Page = {
  path: string
  title: string
  showInMenu: boolean
  components: ComponentType[]
}

export type ComponentType =
  | BannerType
  | TitleParagraphType
  | MediaAndTextType
  | CatalogueType
  | LogoType
  | GenderFilterType
  | CollectionFilterType
  | CollectionMenuType
  | MediaPlayerType
  | ContactFormType
  | SocialNetworkType
  | CarouselType
  | SpacerType

export const useComposition = () => {
  const [brand, setBrand] = useState<Nullable<Brand>>(null)
  const [country, setCountry] = useState<string>()
  const [language, setLanguage] = useState<string>()
  const [partner, setPartner] = useState<string>()
  const [ocp, setOcp] = useState<boolean>()
  const [contacts, setContacts] = useState<Nullable<Contact[]>>([])
  const [options, setOptions] = useState<GlobalOptions>(null)
  const [pages, setPages] = useState<Nullable<Page[]>>(null)
  const [gaTracking, setGaTraking] = useState<boolean>(false)
  const guid = useGuid()

  useEffect(() => {
    if (!process.env.REACT_APP_MOCK) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/virtual-showcases/retail/${guid}`
        )
        .then(res => {
          setPartner(res.data.partner)
          setBrand(res.data.brand)
          setCountry(res.data.country)
          setLanguage(res.data.language)
          setPages(res.data.pages)
          setOptions(res.data.options)
          setContacts(res.data.contacts)
          setOcp(res.data.ocp)
          setGaTraking(res.data.gaTracking)
        })
        .catch((error: AxiosError) => {})
    }
  }, [guid])

  return process.env.REACT_APP_MOCK
    ? mock
    : {
        brand,
        pages,
        language,
        contacts,
        options,
        partner,
        country,
        ocp,
        gaTracking,
      }
}
