import { createUseStyles } from 'react-jss'
import { Brand } from '../../../core/hooks/useComposition'
import { GlobalOptions } from '../../../core/hooks/useGlobalOptions'
import { MQ } from '../../../core/hooks/useMediaQuery'
import { jssTheme } from '../../../core/theme'
import { Nullable } from '../../../core/tools/utilityTypes'

type Props = {
  mq: MQ
  infoTop: number
  brand: Nullable<Brand>
  globalOptions: GlobalOptions
}

export const useStyles = createUseStyles((theme: typeof jssTheme) => ({
  root: ({ mq }: Props) => ({
    display: mq.up.md && 'flex',
    alignItems: 'flex-start',
    marginBottom: '16px',
  }),
  masonry: ({ mq }) => ({
    display: mq.up.md && 'flex',
    position: 'relative',
    width: mq.up.md && '50%',
  }),
  imageWrapper: ({ mq }) => ({
    alignItems: 'center',
    background: theme.palette.background.grey,
    boxSizing: 'border-box',
    display: 'flex',
    marginLeft: mq.up.md && '16px',
    marginBottom: mq.down.xl && '16px',
    padding: '32px',
    width: '100%',
  }),
  image: {
    height: 0,
    paddingTop: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
  },
  thumbnails: ({ mq }) => ({
    display: 'grid',
    flex: '0 0 100px',
    height: 'fit-content',
    gridTemplateColumns: `repeat(${mq.up.md ? 1 : 3}, 1fr)`,
    rowGap: '16px',
    columnGap: '16px',
  }),
  thumbnail: {
    background: theme.palette.background.grey,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
    paddingTop: '100%',
  },
  novelty: {
    background: '#ffffff',
    right: '8px',
    padding: '0 32px',
    position: 'absolute',
    textAlign: 'center',
    top: '8px',
  },
  infoOutterWrapper: ({ mq }) => ({
    width: mq.up.md && '50%',
    textAlign: mq.down.lg && 'center',
    position: 'relative',
  }),
  infoInnerWrapper: ({ mq, infoTop }) => ({
    boxSizing: 'border-box',
    height: '100%',
    padding: `0 ${mq.up.md ? 96 : mq.up.sm ? 48 : 0}px`,
    position: 'relative',
    transition: 'top .5s',
    top: infoTop >= 0 ? 0 : -infoTop,
  }),
  title: {},
  separator: {
    background: '#dbdbdb',
    height: 1,
    width: 80,
  },
  collection: {
    fontWeight: 600,
  },
  reference: ({ brand, globalOptions }) => ({
    fontWeight: 300,
    fontFamily: brand === 'bem' && `"${globalOptions?.titleFont}"`,
    fontSize: brand === 'bem' && 12,
  }),
  description: {
    color: theme.palette.text.grey,
    fontSize: theme.typography.size.small,
    textAlign: 'justify',
  },
  bemDescription: {
    color: theme.palette.text.grey,
    textAlign: 'justify',
    fontSize: 16,
  },
  showMoreButton: ({ mq }) => ({
    width: mq.down.xl && '100%',
    marginTop: '48px',
  }),
  bemTitleCollection: ({ globalOptions }) => ({
    fontSize: 32,
    fontFamily: `"${globalOptions?.paragraphFont}"`,
    textTransform: 'initial',
  }),
  bemTitleReference: {
    fontSize: 20,
  },
}))
