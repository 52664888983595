import { FC, useMemo } from 'react'
import { useStyles } from './styles'

type Props = Partial<{
  defaultValue: string
  label: string
  name: string
  options: string[]
  onChange: (value: string) => void
}>

const SelectField: FC<Props> = props => {
  const name = useMemo(() => `biceps-form__${props.name}`, [props.name])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={name}>
        {props.label}
      </label>
      <select
        disabled={props.options?.length === 1}
        className={classes.input}
        defaultValue={props.defaultValue}
        name={name}
        required
        onChange={event => {
          props.onChange && props.onChange(event.currentTarget.value)
        }}
      >
        {props.options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectField
