import { useEffect, useMemo, useState } from 'react'

export type MQ = {
  up: {
    sm: boolean
    md: boolean
    lg: boolean
  }
  down: {
    sm: boolean
    md: boolean
    lg: boolean
    xl: boolean
  }
}

type MediaQuery = { mq: MQ; width: number }

export const useMediaQuery = (): MediaQuery => {
  const breakpoints = useMemo(
    () => ({
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    }),
    []
  )

  const el =
    document
      .querySelector('biceps-host')
      ?.shadowRoot?.querySelector('biceps-showcase') ||
    document.querySelector('biceps-showcase')

  const [width, setWidth] = useState(el?.getBoundingClientRect().width || 0)

  useEffect(() => {
    const onResize = () => {
      setWidth(el?.getBoundingClientRect().width || 0)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return {
    mq: {
      up: {
        sm: width >= breakpoints.sm,
        md: width >= breakpoints.md,
        lg: width >= breakpoints.lg,
      },
      down: {
        sm: width < breakpoints.xs,
        md: width < breakpoints.sm,
        lg: width < breakpoints.md,
        xl: width < breakpoints.lg,
      },
    },
    width,
  }
}
