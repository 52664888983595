import { createUseStyles } from 'react-jss'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = {
  hasSent: boolean
  mq: MQ
}

export const useStyles = createUseStyles({
  root: ({ mq }: Props) => ({
    margin: mq.up.sm ? '64px' : '32px',
    overflow: 'hidden',
  }),
  formButton: {
    display: 'block',
    margin: 'auto',
  },
  form: {
    margin: 'auto',
    marginTop: '32px',
    maxWidth: 960,
  },
  fields: {
    columnGap: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: '16px',
    rowGap: '16px',
  },
  fullwidthField: {
    marginTop: '16px',
    '& textarea': {
      display: 'block',
    },
  },
  CtaContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: ({ hasSent }: Props) => ({
    background: hasSent ? '#a5d6a7' : '#ef9a9a',
    color: hasSent ? '#2e7d32' : '#c62828',
    borderRadius: 2,
    marginTop: '16px',
    padding: '16px',
  }),
  contact: {
    color: 'inherit',
    marginRight: 8,
  },
})
