import { FC, useEffect, useRef } from 'react'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CarouselType } from '../../core/hooks/useComposition'
import { useMediaQuery } from '../../core/hooks/useMediaQuery'
import { swiperStyles, useStyles } from './styles'

type Props = {
  component: CarouselType
}

const Carousel: FC<Props> = ({ component }) => {
  const root = useRef<HTMLElement>(null)
  const { mq } = useMediaQuery()

  useEffect(() => {
    var style = document.createElement('style')

    style.appendChild(document.createTextNode(swiperStyles))

    root.current?.appendChild(style)
  }, [])

  const classes = useStyles({ ...component.options, mq })

  return (
    <section className={classes.root} ref={root}>
      <Swiper
        autoplay={{
          delay: component.options.delay,
          disableOnInteraction: false,
        }}
        pagination={{ enabled: true, clickable: true }}
        modules={[Autoplay, Pagination]}
      >
        {component.content.map((entry, index) => (
          <SwiperSlide key={index}>
            <div
              className={classes.image}
              role="img"
              aria-label={entry.image.alt}
              style={{ backgroundImage: `url(${entry.image.src1x})` }}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default Carousel
