import { createUseStyles } from 'react-jss'
import { GenderFilterType } from '../../core/hooks/useComposition'
import { MQ } from '../../core/hooks/useMediaQuery'

type Props = GenderFilterType['options'] & { mq: MQ }

export const useStyles = createUseStyles({
  root: ({ mq }: Props) => ({
    columnGap: '16px',
    display: mq.down.md ? 'block' : 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    marginTop: '16px',
    marginBottom: '16px',
  }),
  insert: ({ mq }: Props) => ({
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
    marginBottom: mq.down.md && '16px',
    padding: '25%',
    position: 'relative',
  }),
  title: {
    left: '50%',
    padding: '16px',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  manTitle: ({ manTitleHue }: Props) => ({
    color: manTitleHue === 1 && 'white',
  }),
  womanTitle: ({ womanTitleHue }: Props) => ({
    color: womanTitleHue === 1 && 'white',
  }),
})
