import { createUseStyles } from 'react-jss'
import { MQ } from '../../../core/hooks/useMediaQuery'
import { GlobalOptions } from '../../../core/hooks/useGlobalOptions'

type Props = {
  isSelected?: boolean
  mq: MQ
  options: GlobalOptions
}

export const useStyles = createUseStyles<string, Props>({
  root: ({ isSelected, mq }: Props) => ({
    cursor: 'pointer',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '14px',
    fontWeight: isSelected ? 600 : 300,
    letterSpacing: '1px',
    margin: mq.up.md ? '4px 16px' : '4px auto',
    padding: '8px 16px',
    textAlign: 'left',
  }),
  bemLabel: ({ options }) => ({
    fontSize: 14,
    fontFamily: `"${options?.titleFont}"`,
  }),
  count: {
    fontWeight: 300,
  },
})
