import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  root: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  carousel: {
    display: 'flex',
    margin: 'auto',
    overflowX: 'auto',
    transition: 'background 0.5s',
    '&::-webkit-scrollbar': {
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#f9f9f9',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: '#949494',
      },
    },
  },
  item: {
    cursor: 'pointer',
  },
  image: {
    display: 'block',
    height: 280,
    margin: 'auto',
    objectFit: 'contain',
  },
  title: {
    fontWeight: 600,
    margin: 0,
    textAlign: 'center',
  },
  subtitle: {
    lineHeight: 1,
    marginBottom: 0,
    textAlign: 'center',
  },
})
