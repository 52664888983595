import { FC, useContext, useMemo } from 'react'
import NativeButton from '../../../components/NativeButton'
import { BrandContext } from '../../../core/hooks/useBrand'
import { FiltersContext } from '../../../core/hooks/useFilters'
import { GlobalOptionsContext } from '../../../core/hooks/useGlobalOptions'
import { useMediaQuery } from '../../../core/hooks/useMediaQuery'
import { useStyles } from './styles'
import clsx from 'clsx'

export type FilterViewProps = {
  count: number
  displayName: string
  id: string
  onClick: (id: string) => void
}

const FilterView: FC<FilterViewProps> = props => {
  const filters = useContext(FiltersContext)
  const options = useContext(GlobalOptionsContext)
  const brand = useContext(BrandContext)
  const { mq } = useMediaQuery()

  const isSelected = useMemo(
    () => !!filters.params.find(f => f.id === props.id),
    [filters.params, props.id]
  )

  const classes = useStyles({ isSelected, mq, options })

  return (
    <NativeButton
      className={classes.root}
      noBorder
      noUppercase
      onClick={() => {
        props.onClick && props.onClick(props.id)
      }}
    >
      <span className={clsx({ [classes.bemLabel]: brand === 'bem' })}>
        {props.displayName.toUpperCase()}
      </span>
      {/* <span className={classes.count}>({props.count})</span> */}
    </NativeButton>
  )
}

export default FilterView
