import { FC, useMemo } from 'react'
import { MediaPlayerType } from '../../core/hooks/useComposition'
import { useStyles } from './styles'

type Props = {
  component: MediaPlayerType
}

const MediaPlayer: FC<Props> = ({ component }) => {
  const youTubeId = useMemo(
    () => component.content.video.url.split('/')[3],
    [component.content.video.url]
  )

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder={0}
        height="100%"
        title="YouTube video player"
        width="100%"
        src={`https://www.youtube-nocookie.com/embed/${youTubeId}?modestbranding=1&rel=0&color=white${
          component.options.youtube.autoplay ? '&autoplay=1&mute=1' : ''
        }${component.options.youtube.hideControls ? '&controls=0' : ''}`}
      />
    </div>
  )
}

export default MediaPlayer
