import { createUseStyles } from 'react-jss'
import { GlobalOptions } from '../../core/hooks/useGlobalOptions'

type Props = {
  options: GlobalOptions
}

export const useStyles = createUseStyles<string, Props>({
  root: ({ options }) => ({
    fontFamily: `"${options?.titleFont}"`,
  }),
  link: {
    background: '#424242',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
    padding: '8px 32px',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
})
