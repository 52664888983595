import { createUseStyles } from 'react-jss'
import { MQ } from '../../core/hooks/useMediaQuery'
import { jssTheme } from '../../core/theme'

type Keys =
  | 'root'
  | 'mediaBox'
  | 'backgroundCover'
  | 'pictureContainer'
  | 'video'
  | 'iframe'
  | 'textBox'
  | 'title'
  | 'paragraph'
  | 'cta'

type Props = {
  mq: MQ
}

export const useStyles = createUseStyles<Keys, Props, typeof jssTheme>(
  theme => ({
    root: ({ mq }) => ({
      display: mq.down.lg ? 'block' : 'flex',
    }),
    mediaBox: ({ mq }) => ({
      width: mq.down.lg ? '100%' : '50%',
    }),
    video: ({ mq }) => ({
      height: mq.down.lg ? '56.25vw' : '100%',
    }),
    iframe: {
      display: 'block',
    },
    backgroundCover: {
      position: 'relative',
    },
    pictureContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    textBox: ({ mq }) => ({
      background: '#f9f9f9',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: mq.up.md ? 'flex-start' : 'center',
      textAlign: mq.down.lg && 'center',
      padding: mq.up.lg ? '64px 96px' : '32px 48px',
      width: mq.down.lg ? '100%' : '50%',
    }),
    title: {
      marginBottom: '16px',
      marginTop: 0,
    },
    paragraph: {
      color: theme.palette.text.grey,
      fontSize: theme.typography.size.small,
      margin: 0,
      textAlign: 'justify',
    },
    cta: {
      marginTop: '16px',
    },
  })
)
