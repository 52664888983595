import { createContext } from 'react'
import { Nullable } from '../tools/utilityTypes'

export type GlobalOptions = Nullable<{
  fontFaces: Nullable<{ name: string; url: string }[]>
  titleFont: string
  paragraphFont: string
  footerLogo: boolean
  headerLogo: boolean
}>

export const GlobalOptionsContext = createContext<GlobalOptions>(null)
