import { FC } from 'react'
import { useStyles } from './styles'

type Props = {
  fit: 'cover' | 'contain'
  src1x: string
  src2x?: string
  alt: string
}

const Image: FC<Props> = props => {
  const classes = useStyles()

  return (
    <picture className={classes.root}>
      <img
        className={classes.image}
        srcSet={`${props.src1x} 1x, ${props.src2x || props.src1x} 2x`}
        alt={props.alt}
        style={{ objectFit: props.fit }}
      />
    </picture>
  )
}

export default Image
