import { FC } from 'react'
import { ThemeProvider } from 'react-jss'
import { jssTheme } from './core/theme'
import Main from './views/Main'

const App: FC = _ => (
  <ThemeProvider theme={jssTheme}>
    <Main />
  </ThemeProvider>
)

export default App
