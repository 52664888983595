import { FC, useCallback } from 'react'
import { Route, HashRouter as Router, Routes } from 'react-router-dom'
import { useLocation, useMount, useUpdateEffect } from 'react-use'
import { BrandContext } from '../../core/hooks/useBrand'
import { useComposition } from '../../core/hooks/useComposition'
import { ContactsContext } from '../../core/hooks/useContacts'
import { useDataLayer } from '../../core/hooks/useDataLayer'
import { FiltersContext, useFilters } from '../../core/hooks/useFilters'
import { GlobalOptionsContext } from '../../core/hooks/useGlobalOptions'
import { OcpContext } from '../../core/hooks/useOcp'
import { PagesContext } from '../../core/hooks/usePages'
import Banner from '../Banner'
import Carousel from '../Carousel'
import Catalogue from '../Catalogue'
import CollectionFilter from '../CollectionFilter'
import CollectionMenu from '../CollectionMenu'
import ContactForm from '../ContactForm'
import GenderFilter from '../GenderFilter'
import Logo from '../Logo'
import MediaAndText from '../MediaAndText'
import MediaPlayer from '../MediaPlayer'
import Menu from '../Menu'
import SocialNetworks from '../SocialNetworks'
import Spacer from '../Spacer'
import TitleParagraph from '../TitleParagraph'
import { useStyles } from './styles'

type Props = Partial<{}>

const Main: FC<Props> = props => {
  const {
    brand,
    pages,
    language,
    contacts,
    options,
    partner,
    country,
    ocp,
    gaTracking,
  } = useComposition()
  const filters = useFilters()
  const location = useLocation()

  const { dataLayer } = useDataLayer()

  const appendGoogleFont = useCallback((font?: string) => {
    const link = document.createElement('link')

    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = `//fonts.googleapis.com/css2?family=${font}:wght@300;400;500;600;700&display=swap`

    document.head.appendChild(link)
  }, [])

  useMount(() => {
    dispatchEvent(new CustomEvent('onFetchCompositionDone'))
  })

  useUpdateEffect(() => {
    gaTracking &&
      dataLayer.push({
        event: 'bcps-virtualPageview',
        uri: 'home',
        partner: partner || 'partner',
        brand: brand || 'brand',
        pgcountry: country || 'country',
        pglanguage: language || 'language',
      })
  }, [gaTracking])

  useUpdateEffect(() => {
    appendGoogleFont(options?.titleFont)
    appendGoogleFont(options?.paragraphFont)
  }, [options])

  useUpdateEffect(() => {
    window.scrollTo(0, 0)
  }, [location.hash])

  const logoComponent = {
    template: 'logo' as const,
    content: { brand },
    keywords: {},
  }

  const classes = useStyles({ options })

  return (
    <div className={classes.root}>
      <OcpContext.Provider value={ocp}>
        <BrandContext.Provider value={brand}>
          <PagesContext.Provider value={pages}>
            <ContactsContext.Provider value={contacts}>
              <GlobalOptionsContext.Provider value={options}>
                <FiltersContext.Provider value={filters}>
                  <Router>
                    {options?.headerLogo && <Logo component={logoComponent} />}
                    <Menu />
                    {pages?.length ? (
                      <Routes>
                        <Route path="/">
                          {pages.map(page => (
                            <Route
                              key={page.path}
                              path={page.path}
                              element={page.components.map(
                                (component, index) => {
                                  switch (component.template) {
                                    case 'logo':
                                      return (
                                        <Logo
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'contact-form':
                                      return (
                                        <ContactForm
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'banner':
                                      return (
                                        <Banner
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'title-paragraph':
                                      return (
                                        <TitleParagraph
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'media-and-text':
                                      return (
                                        <MediaAndText
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'collection-filter':
                                      return (
                                        <CollectionFilter
                                          key={index}
                                          language={language}
                                          component={component}
                                        />
                                      )
                                    case 'gender-filter':
                                      return (
                                        <GenderFilter
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'media-player':
                                      return (
                                        <MediaPlayer
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'social-networks':
                                      return (
                                        <SocialNetworks
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'catalogue':
                                      return (
                                        <Catalogue
                                          key={index}
                                          language={language}
                                          component={component}
                                        />
                                      )
                                    case 'collection-menu':
                                      return (
                                        <CollectionMenu
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'carousel':
                                      return (
                                        <Carousel
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    case 'spacer':
                                      return (
                                        <Spacer
                                          key={index}
                                          component={component}
                                        />
                                      )
                                    default:
                                      return null
                                  }
                                }
                              )}
                            />
                          ))}
                        </Route>
                      </Routes>
                    ) : null}
                    {options?.footerLogo && <Logo component={logoComponent} />}
                  </Router>
                </FiltersContext.Provider>
              </GlobalOptionsContext.Provider>
            </ContactsContext.Provider>
          </PagesContext.Provider>
        </BrandContext.Provider>
      </OcpContext.Provider>
    </div>
  )
}

export default Main
