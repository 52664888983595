import { FC } from 'react'
import { CatalogueType } from '../../../core/hooks/useComposition'
import { useMediaQuery } from '../../../core/hooks/useMediaQuery'
import ProductCard from '../ProductCardView'
import { ProductCardProps } from '../ProductCardView/ProductCardView'
import { useStyles } from './styles'

type ProductGridProps = {
  cardsKey: string
  keywords?: CatalogueType['keywords']
  onCardClick: (Args: { reference: string; codePim: string }) => void
  products: Omit<ProductCardProps, 'onClick'>[]
  showReferences?: boolean
  total: number
  options?: CatalogueType['options']
}

const ProductGridView: FC<ProductGridProps> = props => {
  const { mq } = useMediaQuery()

  const classes = useStyles({ mq })

  return (
    <div className={classes.root}>
      <div className={classes.totalWrapper}>
        <p className={classes.total}>
          {props.total} {props.keywords?.products}
        </p>
      </div>
      <div className={classes.cards} key={props.cardsKey}>
        {props.products.map(product => (
          <ProductCard
            key={product.reference}
            {...product}
            showReference={props.showReferences}
            findOutMore={props.keywords?.find_out_more}
            onClick={props.onCardClick}
            nameLogic={props.options?.nameLogic}
          />
        ))}
      </div>
    </div>
  )
}

export default ProductGridView
