import { Brand, Contact, Page } from '../core/hooks/useComposition'
import { GlobalOptions } from '../core/hooks/useGlobalOptions'

type Mock = {
  brand: Brand
  contacts: Contact[]
  country: string
  language: string
  options: GlobalOptions
  pages: Page[]
  partner: string
  ocp: boolean
  gaTracking: boolean
}

const mock: Mock = {
  brand: 'pan',
  contacts: [],
  country: 'France',
  language: 'en',
  partner: 'partner',
  ocp: true,
  gaTracking: false,
  options: {
    fontFaces: null,
    footerLogo: false,
    headerLogo: true,
    paragraphFont: 'Poppins',
    titleFont: 'Poppins',
  },
  pages: [
    {
      path: '/',
      title: 'Home',
      showInMenu: false,
      components: [
        {
          template: 'carousel',
          content: [
            {
              image: {
                src1x:
                  'https://www.panerai.com/content/dam/pan-assets/02-collections/02-01-watch-collection/02-01-09-new-watch-collection/panerai_sub44_plp-02.jpg.transform.generic_header_image_1920.jpg',
                src2x:
                  'https://www.panerai.com/content/dam/pan-assets/02-collections/02-01-watch-collection/02-01-09-new-watch-collection/panerai_sub44_plp-02.jpg.transform.generic_header_image_1920.jpg',
              },
            },
            {
              image: {
                src1x:
                  'https://www.panerai.com/content/dam/pan-assets/02-collections/02-01-watch-collection/02-01-09-new-watch-collection/panerai_sub44_plp-02.jpg.transform.generic_header_image_1920.jpg',
                src2x:
                  'https://www.panerai.com/content/dam/pan-assets/02-collections/02-01-watch-collection/02-01-09-new-watch-collection/panerai_sub44_plp-02.jpg.transform.generic_header_image_1920.jpg',
              },
            },
            {
              image: {
                src1x:
                  'https://www.panerai.com/content/dam/pan-assets/02-collections/02-01-watch-collection/02-01-09-new-watch-collection/panerai_sub44_plp-02.jpg.transform.generic_header_image_1920.jpg',
                src2x:
                  'https://www.panerai.com/content/dam/pan-assets/02-collections/02-01-watch-collection/02-01-09-new-watch-collection/panerai_sub44_plp-02.jpg.transform.generic_header_image_1920.jpg',
              },
            },
          ],
          options: {
            delay: 6000,
            imageSize: 2,
          },
          keywords: {},
        },
      ],
    },
  ],
}

export default mock
